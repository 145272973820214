.search-menu {
    background-color: rgba(100, 11, 11, 0.8);
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
}

/* Header */
.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-decoration: none;
}

.logo img {
    display: block;
    height: 7em;
    text-align: center;
}

.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0.25rem;

    flex-direction: column;
    align-items: center;
}

.vhutitle {
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    font-size: 1.5em;
    padding-top: 0.5rem;
    padding-left: 1rem;
}

/* Title */
.title-out {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.title-lang1 {
    display: none;
}

.title-lang2 {
    display: flex;
}

.title-lang1, .title-lang2 {
    justify-content: end;
    align-self: center;
}

.title-title {
    text-align: center;
    align-self: center;
    color: white;
    font-size: 1.5em;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-decoration: none;
}

/* Menu */

.title-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-direction: column;
}

.title-menu-hide {
    display: none !important;
}

.menuitem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

.menuitem a{
    border-radius: 0.5em;
    text-transform: uppercase;
    color: white;
    margin: 0;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
    white-space: pre-line;
}

@media all and (min-width: 700px) {
    .header {
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: nowrap;
    }

    .title-lang1 {
        display: flex;
        align-self: end;
    }

    .title-lang2 {
        display: none;
    }

    .title-menu {
        align-items: end;
        align-self: end;
    }

    .title-menu-toggle {
        align-self: end;
    }

    .logo {
        position: absolute;
    }

    .title-menu-toggle {
        margin-top: -4em;
    }
}

@media all and (min-width: 1200px) {
    .title-menu {
        flex-direction: row;
        align-self: center;
    }

    .title-menu-hide {
        display: flex !important;
    }

    .header {
        flex-direction: row;
        align-items: flex-start;
    }

    .title-lang1 {
        display: flex;
        align-self: end;
    }

    .title-lang2 {
        display: none;
    }

    .title-menu-toggle {
        display: none;
    }

    .logo {
        position: absolute;
    }

    .title-title {
        font-size: 2em;
    }
}