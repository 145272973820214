.cb-slideshow {
    filter: brightness(55%);
    background-color: black;

    /* default background before animation */
    background-image: url(./picture50.jpg);
    @supports (background-image: url(./picture50.webp)) {
        background-image: url(./picture50.webp);
    }
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.cb-slideshow,
.cb-slideshow:after {
    list-style: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: -10;
}
.cb-slideshow li span {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    color: transparent;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    opacity: 0;
    z-index: -10;
    -webkit-backface-visibility: hidden;
    -webkit-animation: imageAnimation 60s linear infinite 0s;
    -moz-animation: imageAnimation 60s linear infinite 0s;
    -o-animation: imageAnimation 60s linear infinite 0s;
    -ms-animation: imageAnimation 60s linear infinite 0s;
    animation: imageAnimation 60s linear infinite 0s;
}

.cb-slideshow li:nth-child(1) span {
    background-image: url(./picture10.jpg);
    @supports (background-image: url(./picture10.webp)) {
        background-image: url(./picture10.webp);
    }
}
.cb-slideshow li:nth-child(2) span {
    background-image: url(./picture14.jpg);
    @supports (background-image: url(./picture14.webp)) {
        background-image: url(./picture14.webp);
    }
    -webkit-animation-delay: 6s;
    -moz-animation-delay: 6s;
    -o-animation-delay: 6s;
    -ms-animation-delay: 6s;
    animation-delay: 6s;
}
.cb-slideshow li:nth-child(3) span {
    background-image: url(./picture15.jpg);
    @supports (background-image: url(./picture15.webp)) {
        background-image: url(./picture15.webp);
    }
    -webkit-animation-delay: 12s;
    -moz-animation-delay: 12s;
    -o-animation-delay: 12s;
    -ms-animation-delay: 12s;
    animation-delay: 12s;
}
.cb-slideshow li:nth-child(4) span {
    background-image: url(./picture18.jpg);
    @supports (background-image: url(./picture18.webp)) {
        background-image: url(./picture18.webp);
    }
    -webkit-animation-delay: 18s;
    -moz-animation-delay: 18s;
    -o-animation-delay: 18s;
    -ms-animation-delay: 18s;
    animation-delay: 18s;
}
.cb-slideshow li:nth-child(5) span {
    background-image: url(./picture20.jpg);
    @supports (background-image: url(./picture20.webp)) {
        background-image: url(./picture20.webp);
    }
    -webkit-animation-delay: 24s;
    -moz-animation-delay: 24s;
    -o-animation-delay: 24s;
    -ms-animation-delay: 24s;
    animation-delay: 24s;
}
.cb-slideshow li:nth-child(6) span {
    background-image: url(./picture21.jpg);
    @supports (background-image: url(./picture21.webp)) {
        background-image: url(./picture21.webp);
    }
    -webkit-animation-delay: 30s;
    -moz-animation-delay: 30s;
    -o-animation-delay: 30s;
    -ms-animation-delay: 30s;
    animation-delay: 30s;
}
.cb-slideshow li:nth-child(7) span {
    background-image: url(./picture28.jpg);
    @supports (background-image: url(./picture28.webp)) {
        background-image: url(./picture28.webp);
    }
    -webkit-animation-delay: 36s;
    -moz-animation-delay: 36s;
    -o-animation-delay: 36s;
    -ms-animation-delay: 36s;
    animation-delay: 36s;
}
.cb-slideshow li:nth-child(8) span {
    background-image: url(./picture31.jpg);
    @supports (background-image: url(./picture31.webp)) {
        background-image: url(./picture31.webp);
    }
    -webkit-animation-delay: 42s;
    -moz-animation-delay: 42s;
    -o-animation-delay: 42s;
    -ms-animation-delay: 42s;
    animation-delay: 42s;
}
.cb-slideshow li:nth-child(9) span {
    background-image: url(./picture50.jpg);
    @supports (background-image: url(./picture50.webp)) {
        background-image: url(./picture50.webp);
    }
    -webkit-animation-delay: 48s;
    -moz-animation-delay: 48s;
    -o-animation-delay: 48s;
    -ms-animation-delay: 48s;
    animation-delay: 48s;
}

.cb-slideshow li:nth-child(10) span {
    background-image: url(./picture52.jpg);
    @supports (background-image: url(./picture52.webp)) {
        background-image: url(./picture52.webp);
    }
    -webkit-animation-delay: 54s;
    -moz-animation-delay: 54s;
    -o-animation-delay: 54s;
    -ms-animation-delay: 54s;
    animation-delay: 54s;
}

/* Animation for the slideshow images */
@-webkit-keyframes imageAnimation {
    0% {
        opacity: 0;
        -webkit-animation-timing-function: ease-in;
    }
    8% {
        opacity: 1;
        -webkit-transform: scale(1.05);
        -webkit-animation-timing-function: ease-out;
    }
    17% {
        opacity: 1;
        -webkit-transform: scale(1.1);
    }
    25% {
        opacity: 0;
        -webkit-transform: scale(1.1);
    }
    100% { opacity: 0 }
}

@-moz-keyframes imageAnimation {
    0% {
        opacity: 0;
        -moz-animation-timing-function: ease-in;
    }
    8% {
        opacity: 1;
        -moz-transform: scale(1.05);
        -moz-animation-timing-function: ease-out;
    }
    17% {
        opacity: 1;
        -moz-transform: scale(1.1);
    }
    25% {
        opacity: 0;
        -moz-transform: scale(1.1);
    }
    100% { opacity: 0 }
}

@-o-keyframes imageAnimation {
    0% {
        opacity: 0;
        -o-animation-timing-function: ease-in;
    }
    8% {
        opacity: 1;
        -o-transform: scale(1.05);
        -o-animation-timing-function: ease-out;
    }
    17% {
        opacity: 1;
        -o-transform: scale(1.1);
    }
    25% {
        opacity: 0;
        -o-transform: scale(1.1);
    }
    100% { opacity: 0 }
}

@-ms-keyframes imageAnimation {
    0% {
        opacity: 0;
        -ms-animation-timing-function: ease-in;
    }
    8% {
        opacity: 1;
        -ms-transform: scale(1.05);
        -ms-animation-timing-function: ease-out;
    }
    17% {
        opacity: 1;
        -ms-transform: scale(1.1);
    }
    25% {
        opacity: 0;
        -ms-transform: scale(1.1);
    }
    100% { opacity: 0 }
}

@keyframes imageAnimation {
    0% {
        opacity: 0;
        animation-timing-function: ease-in;
    }
    8% {
        opacity: 1;
        transform: scale(1.05);
        animation-timing-function: ease-out;
    }
    17% {
        opacity: 1;
        transform: scale(1.1);
    }
    25% {
        opacity: 0;
        transform: scale(1.1);
    }
    100% { opacity: 0 }
}