@font-face {
    font-family: 'Special Elite';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./assets/special_elite.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

p.indented {
    text-indent: 3em;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-elite {
    font-family: Special Elite;
}

.rem08 {
    font-size: 0.8rem;
}

.color-green-d1 {
    background-color: #353f33;
}

.color-green-d2 {
    background-color: #546154;
}

.color-green-d3 {
    /*background-color: #546154cc;*/
    background-color: #EDDAC4cc;
}

.color-input-yellow {
    background-color: #f2e2be;
}

.search-table-header {
    background-color: #353f33 !important;
    color: white !important;
}

.search-table-row {
    background-color: transparent !important;
    color: black !important;
}

.gal-fvh {
    height: 100vh;
    max-height: 100vh;
    width: 100%;
    max-width: 100%;
}

.gal-siw {
    height: 70vh;
    max-height: 70vh;
    width: 100%;
    max-width: 100%;
}

.gal-x {
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    text-align: right;
}

.active > .page-link, .page-link.active {
    background-color: #353f33;
    color: #e7efe4;
    border-color: #353f33;
}

.page-link {
    color: #353f33;
    border-color: #e7efe4;
    background-color: #e7efe4;
}

.disabled > .page-link {
    color: #353f33;
    border-color: #e7efe4;
    background-color: #e7efe4;
}

.container-w75 {
    width: 100%;
}

@media all and (min-width: 700px) {
    .container-w75 {
        width: 100%;
    }
}

@media all and (min-width: 960px) {
    .container-w75 {
        width: 75%;
    }
}

.container-searchbox {
    width: 90%;
}

@media all and (min-width: 700px) {
    .container-searchbox {
        width: 50%;
    }
}

@media all and (min-width: 960px) {
    .container-searchbox {
        width: 25%;
    }
}

.prewrap {
    white-space: pre-wrap;
}